import React from 'react';
import { LinkButtonPrimary } from 'components/Shared/Button/Button';
import './CurrentCustomersInfoRow.scss';

const CurrentCustomersInfoRow = ({ data }) => (
  <div className="aaa-customers-info-row no-gutters row">
    <div className="aaa-customers-info-row__box col col-12 col-md-4">
      <img
        className="aaa-customers-info-row__image"
        src={data.image.publicURL}
      />
    </div>
    <div className="aaa-customers-info-row__box col col-12 col-md-8">
      <h3 className="aaa-customers-info-row__title">{data.title}</h3>
      <h5 className="aaa-customers-info-row__subtitle">{data.subtitle}</h5>
      <p className="aaa-customers-info-row__desc">{data.description}</p>
      {data.link && data.linkTitle ? (
        <div className="aaa-customers-info-row__link-box">
          <LinkButtonPrimary to={data.link.url}>
            {data.linkTitle}
          </LinkButtonPrimary>
        </div>
      ) : null}
    </div>
  </div>
);

export default CurrentCustomersInfoRow;
