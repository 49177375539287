import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CurrentCustomersInfoContainer from 'components/CurrentCustomersInfoContainer/CurrentCustomersInfoContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const CurrentCustomersPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.currentCustomersYaml.title} />
      <Hero data={data.currentCustomersYaml.hero} />
      <CurrentCustomersInfoContainer
        data={data.currentCustomersYaml.currentCustomersInfoContainer}
      />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default CurrentCustomersPage;

export const pageQuery = graphql`
  query CurrentCustomersPageQuery {
    currentCustomersYaml {
      id
      title

      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        separator
      }

      currentCustomersInfoContainer {
        descriptionRows {
          title
          subtitle
          description
          image {
            publicURL
          }
          linkTitle
          link {
            text
            url
          }
        }
      }
    }
  }
`;
