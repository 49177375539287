import React from 'react';
import CurrentCustomersInfoRow from 'components/CurrentCustomersInfoRow/CurrentCustomersInfoRow';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './CurrentCustomersInfoContainer.scss';

const CurrentCustomersInfoContainer = ({ data }) => (
  <div className="aaa-customers-info">
    <ContentBox>
      {data.descriptionRows.map((row, i) => (
        <CurrentCustomersInfoRow data={row} key={i} />
      ))}
    </ContentBox>
  </div>
);

export default CurrentCustomersInfoContainer;
